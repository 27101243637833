import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Amplify } from 'aws-amplify';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { environment } from 'src/environments/environment';
import { AwsAccountComponent } from './aws-account.component';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: environment.awscognito.userPoolId,
      userPoolClientId: environment.awscognito.userPoolClientId,
      identityPoolId: environment.awscognito.identityPoolId,

      loginWith: {
        email: true,
      }
    },
  },
})

@NgModule({
  declarations: [
    AwsAccountComponent
  ],
  imports: [
    CommonModule,
    AmplifyAuthenticatorModule
  ]
})
export class AwsAccountModule { }
