import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from '../services/user-profiles/user-profile.service';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { UserRole } from '../shared/constants';
import { FlightService } from '../services/flights/flight.service';
import { of } from 'rxjs';

export const PermissionGuard: CanActivateFn = (route, state) => {
  const userProfileService = inject(UserProfileService);
  const flightService = inject(FlightService);
  const router = inject(Router);
  const expectedRoles: UserRole[] = route.data['expectedRoles'];
  const requiredPermissions: string[] = route.data['requiredPermissions'] || [];

  return userProfileService.userProfileAndRoles$.pipe(
    take(3),
    filter(user => user.userRoles && user.userRoles.length > 0),
    switchMap(user => {
      const userRoles = user.userRoles;
      const userPermissions = userProfileService.getUserPermissions();

      // Check roles
      const hasRequiredRole = userRoles.some(role => expectedRoles.includes(role.role));
      if (!hasRequiredRole) {
        router.navigate(['/access-denied']);
        return of(false);
      }

      // Check permissions
      const hasRequiredPermissions = requiredPermissions.every(permission => userPermissions.includes(permission));
      if (!hasRequiredPermissions) {
        router.navigate(['/access-denied']);
        return of(false);
      }

      // Check Permission to Legacy CDR Page
      // if (route.url.toString().includes('call-history-legacy')) {
      //   return flightService.checkFlight().pipe(
      //     switchMap((response) => {
      //       if (response.isFlightOn) {
      //         return userProfileService.checkLegacyCDRAccess(response.customerCode);
      //       } else {
      //         return userProfileService.userProfileData.pipe(
      //           switchMap(userProfile => userProfileService.checkLegacyCDRAccess(userProfile.customerCode))
      //         );
      //       }
      //     }),
      //     map(result => {
      //       if (!result) {
      //         router.navigate(['/access-denied']);
      //         return false;
      //       }
      //       return true;
      //     })
      //   );
      // }

      // If no special checks are needed, return true
      return of(true);
    })
  );
};
