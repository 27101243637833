import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { UserProfileService } from 'src/app/services/user-profiles/user-profile.service';
import { IUserRole } from 'src/app/Models/UserProfiles/UserProfileModels';
import { FlightService } from 'src/app/services/flights/flight.service';
import { catchError } from 'rxjs';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/shared/constants';
@Component({
  selector: 'loading-page',
  imports: [],
  templateUrl: './loading-page.component.html',
  styleUrl: './loading-page.component.scss',
  standalone: true
})
export class LoadingPageComponent implements OnInit {
  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;
  isBaseUser: boolean = false;
  isSuperAdminDashboard: boolean = false;
  flightResponse: any;

  currentUserRoles: IUserRole[] = [];
  constructor(public layoutService: LayoutService,
    private userProfileService: UserProfileService,
    private flightService: FlightService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userProfileService.userRolesData.subscribe(x => {
      this.currentUserRoles = x;
      this.isSuperAdmin = this.checkUserRoles(UserRole.SuperAdmin);
      this.isAdmin = this.checkUserRoles(UserRole.Admin);
      this.isBaseUser = this.checkUserRoles(UserRole.BaseUser);
      this.checkFlight();
    });
  }

  ngDoCheck() {
    //  this.checkFlight();
  }
  checkUserRoles(role: number) {
    return this.currentUserRoles.some(x => x.role === role);
  }

  checkFlight() {
    this.flightService.checkFlight().pipe(
      catchError(error => {
        console.log("Error occurred:", error);
        throw error;
      })
    ).subscribe({
      next: (x: any) => {
        this.flightResponse = x;
        this.setSuperAdminDefaultPage();
      }
    });
  }

  setSuperAdminDefaultPage() {

    if (this.isSuperAdmin && this.flightResponse.customerCode === "") {
      this.router.navigateByUrl("/customers");

    }
    else if (this.isSuperAdmin && this.flightResponse.customerCode !== "") {
      // set flight 
      this.router.navigateByUrl("/admin/my-crew/users?customer=" + this.flightResponse.customerCode);

    }
    else if (!this.isSuperAdmin && this.isAdmin) {
      this.router.navigateByUrl("/admin/my-crew/users");
    }
    else {
      this.router.navigateByUrl("/call-history");
    }
  }
}
