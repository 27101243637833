import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { UserProfileService } from '../services/user-profiles/user-profile.service';
import { from, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { fetchAuthSession } from 'aws-amplify/auth';

@Injectable()
export class LegacyCdrInterceptor implements HttpInterceptor {
  constructor(
    private userProfileService: UserProfileService
  ) { }

  async getTokens(): Promise<{ accessToken: string | null, idToken: string | null }> {
    try {
      const session = await fetchAuthSession();
      if (session && session.tokens) {
        const accessToken = session.tokens.accessToken?.toString() ?? null;
        const idToken = session.tokens.idToken?.toString() ?? null;
        return { accessToken, idToken };
      }
      return { accessToken: null, idToken: null };
    } catch (error) {
      console.error('Error getting tokens:', error);
      throw new Error('Unable to get tokens');
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request URL contains the target domain
    if (req.url.includes(environment.apis.legacy.url)) {
      return this.userProfileService.userProfileAndRoles$.pipe(
        take(1),
        switchMap(userProfileAndRoles => {
          const customerCode = userProfileAndRoles.userProfile.currentCustomerCode || userProfileAndRoles.userProfile.customerCode;
          let clonedReq = req.clone({
            headers:
              req.headers.delete('X-Customer-Code')
                .set('X-Customer', customerCode || '')
          });

          // Use 'from' to convert the promise returned by 'getTokens' into an observable
          return from(this.getTokens()).pipe(
            switchMap(({ accessToken, idToken }) => {
              // Add the access token to the headers if available
              if (accessToken && idToken) {
                let finalReq = clonedReq.clone({
                  headers: clonedReq.headers
                    .set('Authorization', `Bearer ${idToken}`)
                    .set('X-Access-Token', `${accessToken}`)
                });
                return next.handle(finalReq);
              }
              return next.handle(clonedReq);
            })
          );
        })
      );
    } else {
      return next.handle(req);
    }
  }
}

