import { Component } from '@angular/core';
import { LayoutService } from "./service/app.layout.service";
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    standalone: false
})
export class AppFooterComponent {
  year = new Date().getFullYear();
  constructor(public layoutService: LayoutService,
    private translate: TranslateService) { }

  get colorScheme(): string {
    return this.layoutService.config().colorScheme;
  }
}
