import { Component, OnInit } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from '../services/user-profiles/user-profile.service';
import { IUserRole } from '../Models/UserProfiles/UserProfileModels';
import { LanguageService } from '../services/utilities/language.service';
import { catchError } from 'rxjs';
import { UserRole } from '../shared/constants';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    currentUserRoles: IUserRole[] = [];
    isSuperAdmin: boolean = false;
    isAdmin: boolean = false;
    isBaseUser: boolean = false;
    model: any[] = [];
    constructor(public layoutService: LayoutService, private translate: TranslateService,
        private userProfileService: UserProfileService,
        private languageService: LanguageService
    ) {
        this.getSetUserPermissions();
    }

    checkSuperAdmin() {
        const superAdminRole = this.currentUserRoles.find(x => x.role === UserRole.SuperAdmin);
        if (superAdminRole) { return true; }
        return false;
    }
    checkAdmin() {
        const adminRole = this.currentUserRoles.find(x => x.role === UserRole.Admin);
        if (adminRole) { return true; }
        return false;
    }
    checkBaseUser() {
        const baseUserRole = this.currentUserRoles.find(x => x.role === UserRole.BaseUser);
        if (baseUserRole) { return true; }
        return false;
    }

    isMenuItemVisible() {
        return false;
    }
    ngOnInit() { }

    getSetUserPermissions() {
        this.userProfileService.userRolesData.subscribe(x => {
            this.currentUserRoles = x;
            this.isSuperAdmin = this.checkSuperAdmin();
            this.isAdmin = this.checkAdmin();
            this.isBaseUser = this.checkBaseUser();
            this.languageService.currentLanguage.pipe(
                catchError(error => {
                    this.setNavigationItems();
                    throw error;
                })
            ).subscribe({
                next: (lang: any) => {
                    this.translate.use(lang);
                    this.setNavigationItems();
                }
            });
        });
    }

    setNavigationItems() {
        this.model = [
            // {
            //     label: this.translate.instant('Dashboard'),
            //     visible: (!this.isSuperAdmin && (this.isAdmin || this.isBaseUser)) ? true : false,
            //     icon: 'pi pi-home', routerLink: ['/dashboard'],
            // },
            {
                label: this.translate.instant("Customers"),
                visible: this.isSuperAdmin ? true : false,
                icon: 'pi pi-users', routerLink: ['/customers']
            },
            {
                label: this.translate.instant("CallHistory"),
                visible: (this.isSuperAdmin || this.isAdmin || this.isBaseUser) ? true : false,
                icon: 'pi pi-history', routerLink: ['/call-history']
            },
            {
                label: this.translate.instant("Admin"), icon: 'pi pi-cog',
                visible: (this.isSuperAdmin || this.isAdmin) ? true : false,
                items: [
                    {
                        label: this.translate.instant("MyCrew"), icon: 'pi pi-users',
                        items: [
                            {
                                label: this.translate.instant("Users"), icon: 'pi ', routerLink: ['admin/my-crew/users']
                            }
                        ]
                    },
                    {
                        label: this.translate.instant("Integrations"), icon: 'pi pi-key',
                        items: [
                            {
                                label: this.translate.instant("ZohoPhoneBridge"), icon: 'pi ',
                                routerLink: ['admin/integrations/zoho-provider']
                            },
                            {
                                label: this.translate.instant("ApiKeys"), icon: 'pi ',
                                routerLink: ['admin/integrations/api-keys']
                            },

                        ]
                    }
                ]
            },
            {
                label: this.translate.instant("Supports"), icon: 'pi pi-question-circle',
                visible: (this.isSuperAdmin || this.isAdmin || this.isBaseUser) ? true : false,

                items: [
                    {
                        label: this.translate.instant("Tickets"), icon: 'pi pi-ticket',
                        routerLink: ['/support/new-ticket']
                    },
                ]
            }
        ];
    }
}