import { Component, inject, OnInit } from '@angular/core';
// import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from './services/user-profiles/user-profile.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { catchError, debounceTime, filter, takeUntil } from 'rxjs/operators';
import { UserProfile, UserSession } from './Models/UserProfiles/UserProfileModels';
import { ActivatedRoute, Router } from '@angular/router';
import { FlightService } from './services/flights/flight.service';
import { LanguageService } from './services/utilities/language.service';
import { AuthService } from './services/account/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent implements OnInit {

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  isMyInfoLoading: boolean = false;
  errorMessage: string = "";
  isError: boolean = false;
  loginEmail: string | undefined;
  isDifferentAccount: boolean = false;
  private router = inject(Router);
  private translate = inject(TranslateService);
  constructor(
    // private primengConfig: PrimeNGConfig,
    // private translate: TranslateService,
    private userProfileService: UserProfileService,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private route: ActivatedRoute,
    private flightService: FlightService,
    private languageService: LanguageService,
    // private router: Router,
    private accountAuthService: AuthService,
  ) {
    languageService.currentLanguage.subscribe(lang => {
      this.translate.use(lang);
    });
    // if (window.location.href.includes("login_email_start") && window.location.href.includes("login_email_end")) {
    //   this.loginEmail = window.location.href.split("login_email_start")[1].split("login_email_end")[0];
    //   localStorage.setItem("login_email", decodeURIComponent(this.loginEmail));
    // }
    // if (window.location.href.includes("error")  {
    // logout()
    // }
    this.checkAndApplyIdFilter();
  }

  ngOnInit(): void {
    //this.accountAuthService.logout().subscribe(x=>{});
    //this.accountAuthService.logoutFromCognito();
    // this.primengConfig.ripple = true; // TODO: add the ripple effect
    this.authService.instance.enableAccountStorageEvents();
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length > 1) {
          this.accountAuthService.logout().subscribe();
        }
        this.setLoginDisplay();
        if (this.loginDisplay) {
          if (this.accountAuthService.readClaim().tfp !== environment.b2cConfig.b2cPolicies.names.signIn) {
            this.accountAuthService.logout().subscribe();
          }
        }
        if (!this.checkPublicUrls()) {
          if (this.loginDisplay) {
            // if user is already login
            if (localStorage.getItem("login_email")) {
              const login_email = localStorage.getItem("login_email")?.toLocaleLowerCase().trim();
              const readClaim = this.accountAuthService.readClaim();
              if (login_email !== readClaim.emails[0].toLowerCase()) {
                this.signOut();
              } else {
                this.setLoggedInUserEmailAndProvider();
                this.getMyInfo();
              }
            } else {
              this.setLoggedInUserEmailAndProvider();
              this.getMyInfo();
            }
          } else {
            this.router.navigateByUrl("/signin");
          }
        }
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  setLoggedInUserEmailAndProvider() {
    if (this.authService.instance.getAllAccounts().length > 0) {
      let idp = this.authService.instance.getAllAccounts()[0].idTokenClaims?.idp;
      if (idp?.includes("cognito-idp")) localStorage.setItem("login_email_idp", "0");
      else localStorage.setItem("login_email_idp", "1");
      let login_email = this.authService.instance.getAllAccounts()[0].idTokenClaims?.emails![0];
      localStorage.setItem("login_email", login_email!);
    }
  }

  getMyInfo() {
    this.isMyInfoLoading = true;
    this.userProfileService.getMyInfo().pipe(
      catchError(error => {
        if (error.message === '401')
          this.errorMessage = this.translate.instant("401");
        else
          this.errorMessage = this.translate.instant("OopsSomethingWentWrong");
        console.log("Error occurred:", error);
        this.isMyInfoLoading = false;
        this.isError = true;
        throw error;
      })
    ).subscribe({
      next: (x: UserProfile) => {
        this.isError = false;
        var userInfo: UserSession = {
          id: x.id,
          customerCode: x.customerCode,
          currentCustomerCode: "",
          email: x.email,
          isSuperAdmin: x.isSuperAdmin,
          isAdmin: x.isAdmin,
          isBaseUser: x.isBaseUser,
          name: x.name,
          nickname: x.nickname,
          languageId: x.languageId,
          onBoarding: x.onBoarding,
          implicitRoleId: x.implicitRoleId,
          customer: x.customer,
          state: x.state,
          idpProvider: x.idpProvider,
          isSupportEngineer: x.isSupportEngineer
        };
        this.isMyInfoLoading = false;
        this.userProfileService.updateUserProfileData(userInfo);
        this.languageService.changeLanguage(x.languageId);
        this.checkAndTakeFlight();
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  signOut() {
    this.accountAuthService.logout().subscribe();
  }

  checkAndTakeFlight() {
    const params = new URLSearchParams(window.location.search.split('?')[1]);
    const customerCodeInLegacyCDR = params.get('customer');
    const customerCode = this.route.snapshot.queryParamMap.get('customer');
    if (customerCode || customerCodeInLegacyCDR) {
      this.flightService.takeFlight(customerCode || customerCodeInLegacyCDR!).subscribe(x => { });
    }
  }

  checkAndApplyIdFilter() {
    const id: string = this.route.snapshot.queryParamMap.get('id')!;
    if (id) {
      this.router.navigate([`/`], { queryParams: { id: id } });
    }
  }

  checkPublicUrls() {
    if (window.location.pathname.includes("privacy-policy") ||
      window.location.pathname.includes("terms-of-service") ||
      window.location.pathname.includes("signin") ||
      window.location.pathname.includes("signout")
    ) return true;
    return false;
  }
}