<div class="layout-footer custom-footer-layout">
    <div class="footer-start">
        <img src="../../assets/images/logos/Croo_Logo_(black).png" alt="logo" />
        <!-- <img [src]="'assets/layout/images/logo-' + (colorScheme === 'light' ? 'dark' : 'light') + '.png'" alt="logo" /> -->
        <!-- <span class="app-name">Croo</span> -->
    </div>
    <div class="footer-end">
        <a routerLink="/privacy-policy" class="mr-6 no-underline text-color-secondary active:text-color-secondary">
            <span>{{'PrivacyPolicy' | translate}}</span>
        </a>
        <a routerLink="/terms-of-service" class="mr-6 no-underline text-color-secondary	active:text-color-secondary">
            <span>{{'TermsOfService' | translate}}</span>
        </a>
        <span>© </span><span>{{'TheCrooGroup'| translate}} </span> <span>{{year}}</span>
    </div>
</div>
<style>
    .custom-footer-layout {
        padding: 0rem !important;
        align-items: center !important;
    }
</style>