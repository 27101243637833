import Aura from '@primeng/themes/aura';
import { definePreset } from '@primeng/themes';

const AuraCyanPreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '#E8EBF9',    // Very light tint
            100: '#D1D7F3',   // Light tint
            200: '#A3B0E7',   // Slightly lighter tint
            300: '#7F91DB',   // Neutral light tint
            400: '#6677C7',   // Near base color tint
            500: '#4C63B6',   // Base color
            600: '#4257A0',   // Slightly darker shade
            700: '#394B8A',   // Neutral dark shade
            800: '#303F74',   // Dark shade
            900: '#28345E',   // Very dark shade
            950: '#1A223E',   // Deep dark shade
        }
    }
});  
export const PrimeNGThemeOptions = {
    preset: AuraCyanPreset,
    options: {
        darkModeSelector: false || 'none',
    }
}