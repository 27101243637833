import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, combineLatest, filter, map, Observable, of, take } from 'rxjs';
import { ApiService } from '../generic/api.service';
import { IUserRole, UpdateUserProfile, UserProfile, UserSession } from 'src/app/Models/UserProfiles/UserProfileModels';
import { User } from 'src/app/demo/api/user';
import { IDPProvider, UserRole } from 'src/app/shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Customer } from 'src/app/Models/CustomerModels/Customer';
@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  userInfo: UserSession = {} as any;

  /* 
  The `userSession` interface is used to maintain the user session, with or without flight details. 
  If a flight is present, `currentCustomerCode` contains values, and `flightedCustomer` holds the flighted customer information.
  Otherwise, `currentCustomerCode` is an empty string, and `flightedCustomer` is null.
  All the logged-in user peroperties are directly accessible with associated customer.
  */

  userSession: UserSession = {} as any;
  userRoles: IUserRole[] = [];
  public languageId: string = "en";

  private readonly apiBaseUrl = environment.apis.default.url;

  private userProfileBehaviorSubject = new BehaviorSubject(this.userSession);
  public userProfileData = this.userProfileBehaviorSubject.asObservable();

  private userRolesBehaviorSubject = new BehaviorSubject(this.userRoles);
  public userRolesData = this.userRolesBehaviorSubject.asObservable();

  public userProfileAndRoles$ = combineLatest([this.userProfileData, this.userRolesData]).pipe(
    filter(([userProfile, userRoles]) => !!userProfile && !!userRoles),
    map(([userProfile, userRoles]) => ({
      userProfile,
      userRoles
    }))
  );

  updateUserProfileData(userSession: UserSession) {
    this.userRoles = [];
    this.userProfileBehaviorSubject.next(userSession);
    this.userInfo = userSession;

    if (userSession.isSuperAdmin) {
      this.userRoles.push({ role: UserRole.SuperAdmin });
    }
    if (userSession.isAdmin) {
      this.userRoles.push({ role: UserRole.Admin });
    }
    if (userSession.isBaseUser) {
      this.userRoles.push({ role: UserRole.BaseUser });
    }
    this.updateUserRolesData(this.userRoles);
  }

  updateUserRolesData(userRoles: IUserRole[]) {
    this.userRolesBehaviorSubject.next(userRoles);
  }

  constructor(private apiService: ApiService,
    private httpClient: HttpClient
  ) { }

  getMyInfo(): Observable<UserProfile> {
    return this.apiService.get(`myinfo`);
  }

  changeCurrentCustomerCode(customerCode: string): Observable<UserSession> {
    var userInfo: any;
    this.userProfileData.subscribe(x => {
      if (x.isSuperAdmin) {
        userInfo = x;
        userInfo.currentCustomerCode = customerCode;
        if (customerCode != '') {
          this.httpClient.get<Customer>(`${this.apiBaseUrl}/customer`, {
            headers: new HttpHeaders({
              'X-Customer': customerCode
            })
          }).subscribe((response) => {
            userInfo.currentCustomerlegacyCDRAccess = response.legacyCDRAccess;
            userInfo.flightedCustomer = response;
          });
        }
        else {
          userInfo.currentCustomerlegacyCDRAccess = false;
        }
      }
    });
    const response = of(this.userInfo);
    return response;
  }

  checkLegacyCDRAccess(customerCode: string): Observable<boolean> {
    return this.httpClient.get<Customer>(`${this.apiBaseUrl}/customer`, {
      headers: new HttpHeaders({
        'X-Customer': customerCode
      })
    }).pipe(
      map(response => {
        if (!response.isNewCustomer) {
          return response.legacyCDRAccess
        } return false;
      }),
      catchError((error) => {
        console.error(error);
        return of(false)
      })
    );
  }


  updateUserProfile(userProfileDto: UpdateUserProfile): Observable<User> {
    return this.apiService.put("updateuserprofile", userProfileDto);
  }

  getUserPermissions(): string[] {
    return ['VIEW_DASHBOARD', 'VIEW_CUSTOMERS'];
  }
}