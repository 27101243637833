<div class="grid">
    <div class="col-12">
        <div class="card">

            <div class=" py-3 my-2">
                <div class=" py-6 my-6">
                    <p class="text-center py-6 my-6">
                        <i class="pi pi-spin pi-spinner " style="font-size: 4rem"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>