import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserProfileService } from '../user-profiles/user-profile.service';
import { Router } from '@angular/router';
import { UserSession } from 'src/app/Models/UserProfiles/UserProfileModels';

@Injectable({
  providedIn: 'root'
})
export class FlightService {
  response: Response = {
    message: "",
    customerCode: "",
    isFlightOn: false
  }

  private flightResponseSubject = new BehaviorSubject(this.response);
  public flightResponseData = this.flightResponseSubject.asObservable();

  updateFlightData(flightResponse: Response) {
    this.flightResponseSubject.next(flightResponse);
  }
  constructor(private userProfileService: UserProfileService, private router: Router) { }

  takeFlight(customerCode: string, emptyQueryParam?: boolean): Observable<Response> {
    this.userProfileService.changeCurrentCustomerCode(customerCode).subscribe(x => {
      if (x.isSuperAdmin) {
        this.response = { customerCode: x.customerCode, message: "take-flight", isFlightOn: true };
        this.updateFlightData(this.response);
        if (emptyQueryParam) {
          this.router.navigate([`/`], { queryParams: { customer: customerCode } });
        }
      }
    });
    const response = of(this.response);
    return response;
  }

  removeFlight(): Observable<Response> {
    this.userProfileService.changeCurrentCustomerCode("").subscribe(x => {
      this.response = {
        customerCode: x.customerCode, message: "remove-flight",
        isFlightOn: false
      };
      this.updateFlightData(this.response);
      this.router.navigateByUrl(`/customers`);
    });
    const response = of(this.response);
    return response;
  }

  checkFlight(): Observable<Response> {
    var res: any = {};
    this.flightResponseData.subscribe(x => {
      res = x;
    });
    var userInfo: UserSession = {} as any;
    this.userProfileService.userProfileData.subscribe(x => {
      userInfo = x;
      if (userInfo.isSuperAdmin) {
        if (userInfo.currentCustomerCode !== "") {
          res.isFlightOn = true;
          res.customerCode = userInfo.currentCustomerCode;
        } else {
          res.isFlightOn = false;
          res.customerCode = "";
        }
      }
    });
    res.message = "checking";
    this.response = res;
    const response = of(this.response);
    return response;
  }

}
interface Response {
  customerCode: string;
  message: string;
  isFlightOn: boolean;
}