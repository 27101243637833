import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-skeleton-loading',
  templateUrl: './table-skeleton-loading.component.html',
  styleUrl: './table-skeleton-loading.component.scss'
})
export class TableSkeletonLoadingComponent implements OnInit {
  @Input() columns: number = 0;
  @Input() class: string = "";
  @Input() height: string = "2rem";
  @Input() borderRadius: string = "16px";
  displayColumns: any[] = [];

  ngOnInit(): void {
    for (let i = 0; i < this.columns; i++) {
      this.displayColumns.push(`column-${i}`);
    }
  }
}
