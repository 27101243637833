import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-plane-icon',
  templateUrl: './plane-icon.component.html',
  styleUrl: './plane-icon.component.scss'
})
export class PlaneIconComponent {
  @Input() classes: string = " icon";
  @Input() tooltipTitle: string = "";
  @Input() tooltipPosition: string = "top";
  ngOnInit(): void {
    this.classes += " icon";
  }
}
