import { Component, inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserProfileService } from '../services/user-profiles/user-profile.service';
import { TranslateService } from '@ngx-translate/core';
import { UserRole } from '../shared/constants';

interface Breadcrumb {
    label: string;
    url?: string;
}

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html',
    standalone: false
})
export class AppBreadcrumbComponent {

    private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

    readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

    private translate = inject(TranslateService);

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userProfileService: UserProfileService,
    ) {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        this.addBreadcrumb(root, [], breadcrumbs);
        this._breadcrumbs$.next(breadcrumbs);

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(event => {
            const root = this.router.routerState.snapshot.root;
            const breadcrumbs: Breadcrumb[] = [];

            this.addBreadcrumb(root, [], breadcrumbs);
            this._breadcrumbs$.next(breadcrumbs);
        });
    }

    private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
        const routeUrl = parentUrl.concat(route.url.map(url => url.path));
        var breadcrumbData: any[] = [];
        if (route.data['breadcrumb'] && route.data['breadcrumb'].toString().includes('/')) {
            breadcrumbData = route.data['breadcrumb'].split("/");
        }
        const parentBreadcrumb = route.parent && route.parent.data ? route.parent.data['breadcrumb'] : null;
        breadcrumbData.map(breadcrumb => {
            if (breadcrumb && breadcrumb !== parentBreadcrumb) {
                breadcrumbs.push({
                    label: breadcrumb, // route.data['breadcrumb'],
                    url: '/' + routeUrl.join('/')
                });
            }
        })

        if (route.firstChild) {
            this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
        }
        if (breadcrumbs.length <= 0) {
            if (!this.userProfileService.userRoles.find(x => x.role === UserRole.SuperAdmin)) {
                breadcrumbs.push({
                    label: 'Dashboard',
                    url: '/' + routeUrl.join('/')
                });
            }
        }
    }
}
