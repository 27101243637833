<div class="layout-container" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <app-sidebar></app-sidebar>
    <div class="layout-content-wrapper layout-custom-wrapper">
        <div class="layout-content layout-custom-content">
            <div class="layout-content-inner">
                <!-- <app-breadcrumb></app-breadcrumb> -->
                <router-outlet></router-outlet>
                <app-footer></app-footer>
            </div>
        </div>
    </div>
    <app-config></app-config>
</div>

<style>
    /* UiUxCustomScript - responsive layout*/
    @media screen and (min-width: 992px) {
        .layout-custom-wrapper {
            margin-left: 3.5rem !important;
            padding-top: 3.5rem !important;
        }

        .layout-custom-content {
            height: calc(100vh - 3.5rem) !important;
            padding: 1.25rem !important;
        }
    }


    @media screen and (min-width: 1536px) {
        .layout-custom-wrapper {
            margin-left: 5rem !important;
            padding-top: 5rem !important;
        }

        .layout-custom-content {
            height: calc(100vh - 5rem) !important;
            padding: 2rem !important;
        }
    }
</style>