@if(isMyInfoLoading) {
<div class="h-full logo-center ">
    <div>
        <img src="../assets/images/logos/Croo_Logo_(black).png" class="" height="50" />
    </div>
    <div class="text-center ">
        <i class="pi pi-spin pi-spinner " style="font-size: 2rem"></i>
    </div>
</div>
}
@else {
@if(isError){
<div class="h-full logo-center ">
    <div>
        <img src="../assets/images/logos/Croo_Logo_(black).png" class="" height="50" />
    </div>
    <div class="text-center ">
        <span class="text-xl">

            {{errorMessage}}
        </span>
        <p>

            <!-- <span class=" mt-3 underline text-primary " (click)="signOut()">
                {{'SignInIntoAnotherAccount' | translate}}
            </span> -->

            <button type="button" pButton pRipple [label]="'SignInIntoAnotherAccount' | translate"
                class="p-button-primary  mt-3" (click)="signOut()"></button>
        </p>
    </div>
</div>
}
@else{
<router-outlet></router-outlet>
}
}