import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, switchMap, take } from 'rxjs';
import { UserProfile } from '../Models/UserProfiles/UserProfileModels';
import { Injectable } from '@angular/core';
import { UserProfileService } from '../services/user-profiles/user-profile.service';
@Injectable()
export class CustomerCodeInterceptor implements HttpInterceptor {
  userProfile: UserProfile = {} as any;
  constructor(private userProfileService: UserProfileService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAvoidUrl = req.url.includes("myinfo") || req.url.includes("/assets/i18n/en.json");
    if (isAvoidUrl) {
      return next.handle(req);
    } else {
      return this.userProfileService.userProfileAndRoles$.pipe(
        take(1),
        switchMap(userProfileAndRoles => {
          const customerCode = userProfileAndRoles.userProfile.currentCustomerCode || userProfileAndRoles.userProfile.customerCode;
          const clonedReq = req.clone({
            headers: req.headers.set('X-Customer-Code', customerCode)
          });
          return next.handle(clonedReq);
        })
      );
    }
  }
}