<p-sidebar [(visible)]="isVisible" [modal]="false" position="bottom" [showCloseIcon]="true"
  styleClass="p-0 p-sidebar-sm" [style]="{'max-height':'50px', 'background-color':'#F1F3F4'}">
  <ng-template pTemplate="headless">
    <audio id="audio-player" controls style="width: 97%;" (loadeddata)="onLoad($event)" (pause)="onPauseAudio($event)"
      (play)="onPlayAudio($event)" (ended)="onEndAudio($event)">
      <source [src]="audioSrc" type="audio/mpeg" />
    </audio>
    <i class="pi pi-times cursor-pointer close-icon absolute bg-red-600 text-sm p-1 text-white border-circle "
      (click)="closeDialog()" [pTooltip]="'Close'| translate" tooltipPosition="top"></i>
  </ng-template>
</p-sidebar>