import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-plane-departure-icon',
    templateUrl: './plane-departure-icon.component.html',
    styleUrl: './plane-departure-icon.component.scss',
    standalone: false
})
export class PlaneDepartureIconComponent implements OnInit {
  @Input() classes: string = " bg-primary text-white";
  @Input() tooltipTitle: string = "";
  @Input() tooltipPosition: string = "top";
  ngOnInit(): void {
  }
}
