<!-- <button class="layout-config-button p-link" style="cursor: pointer" type="button" (click)="onConfigButtonClick()">
    <i class="pi pi-cog"></i>
</button> -->

<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-config-sidebar w-18rem">
    <ng-container *ngIf="!minimal">
        <h5>Menu Type</h5>
        <div class="flex flex-wrap row-gap-3">
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton name="menuMode" value="static" [(ngModel)]="menuMode" inputId="mode1"></p-radioButton>
                <label for="mode1">Static</label>
            </div>
            <div class="flex align-items-center gap-2 w-6 pl-2">
                <p-radioButton name="menuMode" value="overlay" [(ngModel)]="menuMode" inputId="mode2"></p-radioButton>
                <label for="mode2">Overlay</label>
            </div>
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton name="menuMode" value="slim" [(ngModel)]="menuMode" inputId="mode3"></p-radioButton>
                <label for="mode3">Slim</label>
            </div>
            <div class="flex align-items-center gap-2 w-6 pl-2">
                <p-radioButton name="menuMode" value="slim-plus" [(ngModel)]="menuMode" inputId="mode4"></p-radioButton>
                <label for="mode3">Slim +</label>
            </div>
        </div>
      
        <hr class="surface-border"/>
    </ng-container>

    <h5>Color Scheme</h5>
    <div class="flex">
        <div class="field-radiobutton flex-1">
            <p-radioButton name="colorScheme" value="light" [(ngModel)]="colorScheme" inputId="scheme1"></p-radioButton>
            <label for="scheme1">Light</label>
        </div>
        <div class="field-radiobutton flex-1">
            <p-radioButton name="colorScheme" value="dark" [(ngModel)]="colorScheme" inputId="scheme2"></p-radioButton>
            <label for="scheme2">Dark</label>
        </div>
    </div>

    <ng-container *ngIf="!minimal">
        <h5>Layout Theme</h5>
        <div class="field-radiobutton">
            <p-radioButton name="menuTheme" value="colorScheme" [(ngModel)]="menuTheme" inputId="menutheme-colorscheme"></p-radioButton>
            <label for="menutheme-colorscheme">Color Scheme</label>
        </div>
        <div class="field-radiobutton">
            <p-radioButton name="menuTheme" value="primaryColor" [(ngModel)]="menuTheme" inputId="menutheme-primarycolor" [disabled]="colorScheme === 'dark'"></p-radioButton>
            <label for="menutheme-primarycolor">Primary Color (Light Only)</label>
        </div>
    </ng-container>

    <h5>Themes</h5>
    <div class="flex flex-wrap gap-3">
        <div *ngFor="let theme of componentThemes">
            <a style="cursor: pointer" (click)="changeTheme(theme.name)" class="inline-flex justify-content-center align-items-center w-2rem h-2rem border-round" 
                [ngStyle]="{'background-color': colorScheme === 'light' ? theme.lightColor : theme.darkColor, 'color': colorScheme === 'light' ? '#ffffff' : '#000000'}">
                <i class="pi pi-check" *ngIf="currentTheme === theme.name"></i>
            </a>
        </div>
    </div>

    <h5>Scale</h5>
    <div class="flex align-items-center">
        <button icon="pi pi-minus" type="button" pButton (click)="decrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem mr-2" [disabled]="scale === scales[0]"></button>
        <div class="flex gap-3 align-items-center">
            <i class="pi pi-circle-fill text-300" *ngFor="let s of scales" [ngClass]="{'text-primary-500': s === scale}"></i>
        </div>
        <button icon="pi pi-plus"  type="button" pButton (click)="incrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem ml-2" [disabled]="scale === scales[scales.length - 1]"></button>
    </div>

    <h5>Input Style</h5>
    <div class="flex">
        <div class="field-radiobutton flex-1">
            <p-radioButton name="inputStyle" value="outlined" [(ngModel)]="inputStyle" inputId="inputStyle1"></p-radioButton>
            <label for="inputStyle1">Outlined</label>
        </div>
        <div class="field-radiobutton flex-1">
            <p-radioButton name="inputStyle" value="filled" [(ngModel)]="inputStyle" inputId="inputStyle2"></p-radioButton>
            <label for="inputStyle2">Filled</label>
        </div>
    </div>

    <h5>Ripple Effect</h5>
    <p-inputSwitch [(ngModel)]="ripple"></p-inputSwitch>
</p-sidebar>
