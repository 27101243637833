<div class="layout-topbar layout-custom-topbar">
    <a class="app-logo" routerLink="/">
        <img alt="app logo" [src]="logo">
        <!-- <span class="app-name">Croo</span> -->
    </a>

    <button #menubutton class="topbar-menubutton p-link" type="button" (click)="onMenuButtonClick()">
        <span></span>
    </button>

    <ul class="topbar-menu">
        <li *ngFor="let item of tabs; let i = index">
            <a [routerLink]="item.routerLink" routerLinkActive="active-route"
                [routerLinkActiveOptions]="item.routerLinkActiveOptions||{paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'}"
                [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling"
                [preserveFragment]="item.preserveFragment!" [skipLocationChange]="item.skipLocationChange!"
                [replaceUrl]="item.replaceUrl!" [state]="item.state" [queryParams]="item.queryParams">
                <span>{{item.label}}</span>
            </a>
            <i class="pi pi-times" (click)="removeTab($event, item, i)"></i>
        </li>
        <li *ngIf="!tabs || tabs.length === 0" class="topbar-menu-empty">
            <div *ngIf="this.flightResponse.isFlightOn">
                <button type="button" pButton pRipple class="p-button-primary text-sm  font-bold w-full "
                    *ngIf="userInfo.isSuperAdmin" (click)="removeFlight()">
                    <app-plane-icon classes="mr-2"></app-plane-icon> {{'TravellingTo' | translate}}
                    {{flightResponse.customerCode}}
                </button>
            </div>
        </li>
    </ul>

    <!-- <div class="topbar-search" [ngClass]="{'topbar-search-active': searchActive}">
        <button class="topbar-searchbutton p-link" type="button" (click)="activateSearch()">
            <i class="pi pi-search"></i>
        </button>

        <div class="search-input-wrapper">
            <span class="p-input-icon-right">
                <input #searchinput type="text" pInputText placeholder="Search" (blur)="deactivateSearch()"
                    (keydown.escape)="deactivateSearch()" />
                <i class="pi pi-search"></i>
            </span>
        </div>
    </div> -->

    @if(loginDisplay && !isLogoutLoading) {
    <div class="topbar-profile">
        <button class="topbar-profile-button p-link" type="button" pStyleClass="@next" enterClass="hidden"
            enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
            <p-avatar [label]="userNameLable" styleClass="mr-2 custom-topbar-profile-icon" size="normal"
                shape="circle" />
            <span class="profile-details">
                <span class="profile-name">{{userInfo.name}}</span>
                <span class="profile-job">{{userInfo.customerCode}}</span>
            </span>
            <i class="pi pi-angle-down"></i>
        </button>
        <ul
            class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full sm:w-12rem mt-2 right-0 top-auto">
            <li>
                <!-- 
              
                <a pRipple
                    class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="pi pi-inbox mr-3"></i>
                    <span>Inbox</span>
                </a>
                <a pRipple
                    class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="pi pi-language mr-3"></i>
                    <span>Language</span>
                </a>
                <a pRipple
                    class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="pi pi-cog mr-3"></i>
                    <span>Settings</span>
                </a> -->
                <a pRipple routerLink="/profile/me"
                    class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="pi pi-user mr-3"></i>
                    <span>{{'Profile'| translate}}</span>
                </a>
                <a pRipple (click)="logout()"
                    class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="pi pi-power-off mr-3"></i>
                    <span>{{'SignOut'| translate}}</span>
                </a>
            </li>
        </ul>
    </div>
    }@else if(loginDisplay && !isLogoutLoading) {
    <div>
        <a pRipple (click)="login()"
            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
            <i class="pi pi-power-off mr-3"></i>
            <span>{{'Signin'| translate}}</span>
        </a>
    </div>
    }@else {
    <div>
        <a pRipple
            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
            <i class="pi pi-spin pi-spinner mr-3"></i>
            <span>{{'SignOut'| translate}}</span>
        </a>
    </div>
    }
</div>

<style>
    @media screen and (min-width: 992px) {
        .layout-custom-topbar {
            height: 3.5rem !important;
            padding: 0 0.5rem 0 0.5rem !important;
        }

        .custom-topbar-profile-icon {
            width: 2rem !important;
            height: 2rem !important;
            font-size: 1rem !important;
        }
    }

    @media screen and (min-width: 1536px) {
        .layout-custom-topbar {
            height: 5rem !important;
            padding: 0 2rem 0 1rem !important;
        }

        .custom-topbar-profile-icon {
            width: 2.5rem !important;
            height: 2.5rem !important;
            font-size: 1.25rem !important;
        }
    }
</style>