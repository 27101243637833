import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
@Pipe({
  name: 'urlSanitize',
  standalone: false
})
export class UrlSanitizePipe implements PipeTransform {
constructor(private sanitizer: DomSanitizer) { }

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }
  transform(url:string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
