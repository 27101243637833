import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AudioState } from 'src/app/shared/constants';

@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrl: './audio-player.component.scss',
    standalone: false
})
export class AudioPlayerComponent implements OnInit {

  @Input() audioSrc: string | undefined;
  @Input() stateType: number | undefined;
  @Output() playerEvents = new EventEmitter<number>();
  isVisible: boolean = false;
  constructor() { }

  ngOnInit(): void { }

  ngOnChanges() {
    switch (this.stateType) {
      case AudioState.Start:
        this.isVisible = true;
        break;
      case AudioState.Play:
        this.play();
        break;
      case AudioState.Pause:
        break;
      case AudioState.End:
        this.stop();
        this.isVisible = false;
        break;
    }
  }
  play() {
    const player = document.getElementById('audio-player') as HTMLAudioElement;
    if (player) {
      player.play();
    }
  }

  pause() { }

  stop() {
    const player = document.getElementById('audio-player') as HTMLAudioElement;
    if (player) {
      player.pause();
      player.currentTime = 0;
    }
  }

  emitEvents() {
  }

  closeDialog() {
    this.stop();
    this.playerEvents.emit(AudioState.End);
    this.isVisible = false;
  }

  onPlayAudio(event: any) {
    this.playerEvents.emit(AudioState.Play);
  }
  onPauseAudio(event: any) {
    this.playerEvents.emit(AudioState.Pause);
  }
  onEndAudio(event: any) {
    this.playerEvents.emit(AudioState.End);
  }
  onLoad(event: any) {
    this.playerEvents.emit(AudioState.Start);
  }
}