import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { PermissionGuard } from './guards/permission.guard';
import { LoadingPageComponent } from './home/loading-page/loading-page.component';
import { UserRole } from './shared/constants';
import { Breadcrumb } from 'primeng/breadcrumb';
import { HangfireDashboardComponent } from './hangfire/hangfire-dashboard/hangfire-dashboard.component';

const routes: Routes = [
    {
        path: '', component: AppLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'loading',
                pathMatch: 'full'
            },
            {
                path: 'loading', component: LoadingPageComponent,
                data: {
                    breadcrumb: "Loading",
                    expectedRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.BaseUser],
                },
                canActivate: [MsalGuard, PermissionGuard]
            },
            // {
            //     path: 'dashboard', component: DashboardComponent,
            //     data: {
            //         breadcrumb: "Dashboard",
            //         expectedRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.BaseUser],
            //         // requiredPermissions: ['VIEW_DASHBOARD']
            //     },
            //     canActivate: [MsalGuard, PermissionGuard]
            // },
            {
                path: 'call-history',
                data: {
                    breadcrumb: "CallHistory",
                    expectedRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.BaseUser],
                },
                loadChildren: () => import('./cdr/cdr.module').then(m => m.CdrModule),
                canActivate: [MsalGuard, PermissionGuard]
            },
            {
                path: 'call-history-legacy',
                data: {
                    breadcrumb: "CallHistoryLegacy",
                    expectedRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.BaseUser],
                },
                loadChildren: () => import('./cdr-legacy/cdr-legacy.module').then(m => m.CdrLegacyModule),
                canActivate: [MsalGuard, PermissionGuard]
            },
            {
                path: 'customers', data: {
                    breadcrumb: 'Customers',
                    expectedRoles: [UserRole.SuperAdmin],
                }, loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
                canActivate: [MsalGuard, PermissionGuard]
            },
            {
                path: 'admin/integrations', data: {
                    breadcrumb: UserRole.Admin,
                    expectedRoles: [UserRole.SuperAdmin, UserRole.Admin],
                }, loadChildren: () => import('./api-keys-integrations/api-keys-integrations.module').then(m => m.ApiKeysIntegrationsModule),
                canActivate: [MsalGuard, PermissionGuard]
            },
            {
                path: 'admin/integrations', data: {
                    breadcrumb: UserRole.Admin,
                    expectedRoles: [UserRole.SuperAdmin, UserRole.Admin],
                }, loadChildren: () => import('./consumers-integrations/consumers-integrations.module').then(m => m.ConsumersIntegrationsModule),
                canActivate: [MsalGuard, PermissionGuard]
            },
            {
                path: 'admin/my-crew', data: {
                    breadcrumb: UserRole.Admin,
                    expectedRoles: [UserRole.SuperAdmin, UserRole.Admin],
                }, loadChildren: () => import('./my-crew/my-crew.module').then(m => m.MyCrewModule),
                canActivate: [MsalGuard, PermissionGuard]
            },
            {
                path: 'support', data: {
                    breadcrumb: 'Support',
                    expectedRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.BaseUser],

                }, loadChildren: () => import('./supports/support.module').then(m => m.SupportModule),
                canActivate: [MsalGuard, PermissionGuard]
            },
            {
                path: 'profile', data: {
                    breadcrumb: 'Profile',
                    expectedRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.BaseUser],

                }, loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesModule),
                canActivate: [MsalGuard, PermissionGuard]
            },
            //TODO: Uncommnet below code to enable hangfire dashboard on UI.
            // {
            //     path: 'hangfire-dash', component: HangfireDashboardComponent,
            //     data: {
            //         breadcrumb: "Dashboard",
            //         expectedRoles: [UserRole.SupportEngineer]
            //     },
            //     canActivate: [MsalGuard, PermissionGuard]
            // },
        ]
    },
    {
        path: 'access-denied', data: { breadcrumb: 'AccessDenied' },
        loadChildren: () => import('./shared/accessdenied/accessdenied.module').then(m => m.AccessdeniedModule)
    },
    // { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
    // { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule) },
    { path: 'privacy-policy', loadChildren: () => import('./public-pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
    { path: 'terms-of-service', loadChildren: () => import('./public-pages/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule) },
    { path: 'signin', loadChildren: () => import('./public-pages/account/account.module').then(m => m.AccountModule) },
    { path: 'signout', loadComponent: () => import('./public-pages/account/signout/signout.component').then(c => c.SignoutComponent) },
    { path: 'notfound', loadChildren: () => import('./demo/components/notfound/notfound.module').then(m => m.NotfoundModule) },

    { path: '**', redirectTo: '/notfound' },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // Don't perform initial navigation in iframes or popups
            scrollPositionRestoration: 'enabled',
            initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
        })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
