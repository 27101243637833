import { Component } from '@angular/core';

@Component({
    selector: 'app-check-download-icon',
    templateUrl: './check-download-icon.component.html',
    styleUrl: './check-download-icon.component.scss',
    standalone: false
})
export class CheckDownloadIconComponent {

}
