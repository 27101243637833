import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, protectedResources } from './auth-config';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomerCodeInterceptor } from './interceptors/customer-code.interceptor';
import { SharedModule } from './shared/shared/shared.module';
import { LegacyCdrInterceptor } from './interceptors/legacycdr.interceptor';
import { AwsAccountModule } from './aws-account/aws-account.module';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    imports: [
        AppLayoutModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AwsAccountModule,
        MsalModule.forRoot(new PublicClientApplication(msalConfig),
            {
                interactionType: InteractionType.Redirect,
                authRequest: {
                    scopes: protectedResources.crooListApi.scopes,
                    domainHint: localStorage.getItem("login_email_idp") === "0" ? "awscognito" : "",
                    loginHint: localStorage.getItem("login_email") ? localStorage.getItem("login_email")! : ""
                },

            },
            {
                interactionType: InteractionType.Redirect,
                authRequest: {
                    domainHint: localStorage.getItem("login_email_idp") === "0" ? "awscognito" : "",
                    loginHint: localStorage.getItem("login_email") ? localStorage.getItem("login_email")! : "" 
                },
                protectedResourceMap: new Map([
                    [
                        protectedResources.crooListApi.endpoint, protectedResources.crooListApi.scopes,

                    ]
                ])
            }),
        SharedModule
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: CustomerCodeInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LegacyCdrInterceptor, multi: true },
        MsalGuard,
    ],
    bootstrap: [AppComponent,
        MsalRedirectComponent
    ]
})
export class AppModule { }
