import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-check-play-icon',
    templateUrl: './check-play-icon.component.html',
    styleUrl: './check-play-icon.component.scss',
    standalone: false
})
export class CheckPlayIconComponent {
  @Input() size: number = 22;
}
