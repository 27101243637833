import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { IDPProvider } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loginDisplay: boolean = false;
  private readonly redirectUrl = environment.baseUrl;
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: Claim[] = [];
  private readonly _destroying$ = new Subject<void>();

  isUserSignIn: boolean = false;
  private signinUserBehaviorSubject = new BehaviorSubject(this.isUserSignIn);
  public signinUserData = this.signinUserBehaviorSubject.asObservable();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cognitoService: AuthenticatorService
  ) { }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
    this.loginDisplay = true;
    const response = this.loginDisplay;
    return response;
  }

  logout(): Observable<boolean> {
    try {
      const status = this.cognitoService.authStatus;
      if (status === "authenticated") {
        this.cognitoService.signOut();
      }
    } catch { console.log("not logout"); }
    finally {
      if (this.authService.instance.getAllAccounts().length > 0) {
        const userIdp = Number.parseInt(localStorage.getItem("login_email_idp")!);
        localStorage.removeItem("login_email_idp");
        localStorage.removeItem("login_email");
        if (userIdp === IDPProvider.AWSConginto) {
          const b2cCognitoUrl = `${environment.b2cCognitoConfig.domain}/logout?client_id=${environment.b2cCognitoConfig.clientId}&logout_uri=${environment.baseUrl}/signout`;
          window.location.href = b2cCognitoUrl;
        } else {
          this.logoutFromB2c();
        }
      }
    }
    this.loginDisplay = false;
    this._destroying$.next(undefined);
    this._destroying$.complete();
    const response = of(this.loginDisplay);
    return response;
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    const respone = this.loginDisplay;
    return respone;
  }

  logoutFromB2c() {
    this.authService.logoutRedirect({ postLogoutRedirectUri: this.redirectUrl }).pipe(
      catchError(error => {
        throw error;
      })
    ).subscribe({
      next: (respone: any) => {
        return;
      }
    });

  }

  readClaim() {
    var claim: any = {};
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None || status === InteractionStatus.HandleRedirect),
        takeUntil(this._destroying$)
      )
      .subscribe((x: any) => {
        this.checkAndSetActiveAccount();
        claim = this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims)
      })
    return claim;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  getClaims(claims: any) {
    let list: Claim[] = new Array<Claim>();
    Object.keys(claims).forEach(function (k, v) {
      let c = new Claim()
      c.id = v;
      c.claim = k;
      c.value = claims ? claims[k] : null;
      list.push(c);
    });
    this.dataSource = list;
    return claims;
  }

  isUserAlreadySignIn(): Observable<boolean> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$),
      map(() => {
        const isUserSignedIn = this.authService.instance.getAllAccounts().length > 0;
        this.signinUserBehaviorSubject.next(isUserSignedIn);
        return isUserSignedIn;
      })
    );
  }
}
export class Claim {
  id: number = 0;
  claim: string = "";
  value: string = "";
}