import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
const logoutRedirectUri = environment.baseUrl;

export const b2cPolicies = {
    names: environment.b2cConfig.b2cPolicies.names,
    authorities: {
        signIn: {
            authority: `https://croocore.b2clogin.com/croocore.onmicrosoft.com/${environment.b2cConfig.b2cPolicies.names.signIn}`,
        },
        signUp: {
            authority: `https://croocore.b2clogin.com/croocore.onmicrosoft.com/${environment.b2cConfig.b2cPolicies.names.signUp}`,
        },
        // editProfile: {
        //     authority: `https://croocore.b2clogin.com/croocore.onmicrosoft.com/${environment.b2cConfig.b2cPolicies.names.editProfile}`
        // }
    },
    authorityDomain: "croocore.b2clogin.com"
};

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.b2cConfig.msalConfig.auth.clientId,
        authority: b2cPolicies.authorities.signIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: logoutRedirectUri,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
    },
    system: {
        loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

export const protectedResources = {
    crooListApi: {
        endpoint: environment.apis.default.url,
        scopes: environment.b2cConfig.protectedResources.crooListApi.scopes
    },
}
export const loginRequest = environment.b2cConfig.loginRequest;
