import { Component } from '@angular/core';

@Component({
  selector: 'app-aws-account',
  templateUrl: './aws-account.component.html',
  styleUrl: './aws-account.component.scss'
})
export class AwsAccountComponent {

}
