import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { FlightService } from '../services/flights/flight.service';
import { catchError } from 'rxjs';
import { AuthService } from '../services/account/auth.service';
import { UserProfileService } from '../services/user-profiles/user-profile.service';
import { UserSession } from '../Models/UserProfiles/UserProfileModels';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

    menu: MenuItem[] = [];
    loginDisplay = false;
    claim: any = {};
    userNameLable: string = "";
    @ViewChild('searchinput') searchInput!: ElementRef;

    @ViewChild('menubutton') menuButton!: ElementRef;

    searchActive: boolean = false;
    flightResponse: any;
    userInfo: UserSession = {} as any;
    isLogoutLoading: boolean = false;

    constructor(
        public layoutService: LayoutService,
        private flightService: FlightService,
        private authService: AuthService,
        private userProfileService: UserProfileService,
        private translate: TranslateService,
        private router: Router,
    ) { }
    ngOnInit(): void {
        this.checkUserLogin();
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    activateSearch() {
        this.searchActive = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    deactivateSearch() {
        this.searchActive = false;
    }

    removeTab(event: MouseEvent, item: MenuItem, index: number) {
        this.layoutService.onTabClose(item, index);
        event.preventDefault();
    }

    get layoutTheme(): string {
        return this.layoutService.config().layoutTheme;
    }

    get colorScheme(): string {
        return this.layoutService.config().colorScheme;
    }

    get logo(): string {
        const path = 'assets/layout/images/logo-';
        const logo = (this.layoutTheme === 'primaryColor' && !(this.layoutService.config().theme == "yellow")) ? 'light.png' : (this.colorScheme === 'light' ? 'dark.png' : 'light.png');
        // return path + logo;

        return 'assets/images/logos/Croo_Logo_(black).png'
    }

    get tabs(): MenuItem[] {
        return this.layoutService.tabs;
    }

    removeFlight() {
        this.flightService.removeFlight().pipe(
            catchError(error => {
                console.log("Error occurred:", error);
                //   this.showError("Error", error.
                //     statusText, error.message);
                throw error;
            })
        ).subscribe({
            next: (x: any) => {
            }
        });
    }

    ngDoCheck() {
        this.checkFlight();
    }

    checkFlight() {
        this.flightService.checkFlight().pipe(
            catchError(error => {
                console.log("Error occurred:", error);
                //   this.showError("Error", error.
                //     statusText, error.message);
                throw error;

            })
        ).subscribe({
            next: (x: any) => {
                this.flightResponse = x;
            }
        });
    }
    logout() {
        this.isLogoutLoading = true;
        this.loginDisplay = false;
        this.authService.logout().subscribe(x => {
            this.isLogoutLoading = x;
        });
    }

    login() {
        this.loginDisplay = this.authService.login();
    }

    checkUserLogin() {
        this.loginDisplay = this.authService.setLoginDisplay();
        if (this.loginDisplay) {
            this.userProfileService.userProfileData.subscribe(x => {
                this.userInfo = x;
                this.getLableOfUserName();
            });
        }
    }

    getLableOfUserName() {
        if (this.userInfo.name)
            this.userNameLable = this.userInfo?.name!.charAt(0);
    }

    gotoUserProfile() {
        this.router.navigateByUrl(`/profile/me`);
    }
}
