export const environment = {
    production: false,
    development: true,
    selectedLanguageCulture: "en",
    baseUrl: window.location.origin,
    apis: {
        default: {
            url: `${window.location.origin}/api/v1`,
            rootNamespace: 'croo',
        },
        legacy: {
            url: "https://api.croo.io/v1"
        },
        azureFunction: {
            url: "https://apistgazapp.mycroo.io/wapi"
        },
        hangfire: {
            url: `${window.location.origin}/jobs`
          }
    },
    awscognito: {
        userPoolId: "us-east-1_sCUHdFnnD",
        userPoolClientId: "a421ucnajl9sr37phokvvu1m2",
        identityPoolId: "us-east-1:54f823d7-ebfe-429f-b24d-94b1b530fe0d"
    },
    b2cCognitoConfig: {
        domain: "https://croossoprod1.auth.us-east-1.amazoncognito.com",
        clientId: "3lg5opnodi8pf6l666pehqr7te",
        redirectUri: "https://croocore.b2clogin.com/croocore.onmicrosoft.com/oauth2/authresp",
        scope: "openid+email+phone"
    },
    b2cConfig: {
        b2cPolicies: {
            names: {
                signUp: "B2C_1_SignUp_Dev01",
                signIn: "B2C_1_SignUp_SignIn_Dev01",
            }
        },
        msalConfig: {
            auth: {
                clientId: '9a2db086-446e-4289-aee4-c65abb7d2e43'
            }
        },
        protectedResources: {
            crooListApi: {
                endpoint: `${window.location.origin}/api/v1`,
                scopes: ["https://croocore.onmicrosoft.com/tasks-api/tasks.read",
                    "https://croocore.onmicrosoft.com/tasks-api/tasks.write"
                ],
            },
        },
        loginRequest: {
            scopes: ["openid"]
        }
    }
};