<div class="layout-sidebar sidebar-custom-layout">

    <div #menuContainer class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>

<style>
    /* UiUxCustomScript - Reduce Sidebar and Topbar when screen size is below 1280px*/
    @media screen and (min-width: 992px) {
        .sidebar-custom-layout {
            width: 3.5rem !important;
        }
    }

    @media screen and (min-width: 1536px) {
        .sidebar-custom-layout {
            width: 5rem !important;
        }
    }

</style>