import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private currentLanguageBehaviorSubject = new BehaviorSubject("en");
  public currentLanguage = this.currentLanguageBehaviorSubject.asObservable();

  constructor(private translate: TranslateService) { }
  changeLanguage(language: string): Observable<string> {
    if (language !== "en" && language !== "fr") {
      language = "en";
    }
    this.translate.use(language).subscribe(() => {
      this.currentLanguageBehaviorSubject.next(language);
    });
    return of(language);
  }
}

